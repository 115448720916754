import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import FormularioCalificaciones from "../components/FormularioCalificaciones";
import { evaluacionActions } from "../handlers/redux";
import {
  calcularPuntajeFormulario,
  ESTADOS_EVALUACION,
  ESTADOS_FORMULARIO,
  ETAPAS,
  getPuntuacionesCustom,
  modificarCalificacionHelper,
  modificarCalificacionKPIHelper,
  obtenerCalificacionesFormulario,
  obtenerCompetenciasCalificadasCopia,
} from "../helpers/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";

const ETAPA = ETAPAS.CALIBRACION;

function FormularioCalibracionPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idEvaluacion, idFormulario } = params;
  const usuario = getUserFromCookie();

  const empresa = useSelector((state) => state.colaborador.empresa);
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacion_loading = useSelector((state) => state.evaluacion.evaluacion_loading);
  const formulario = useSelector((state) => state.evaluacion.formulario);
  const formulario_loading = useSelector((state) => state.evaluacion.formulario_loading);

  const { habilitar_puntaje_evaluacion_mayor_100 = false } = empresa;

  const [fueEditado, setFueEditado] = useState(false);
  const [porcentajesFormulario, setPorcentajesFormulario] = useState({});

  const modificarCalificacion = (
    competenciasKey,
    competencias,
    competenciaId,
    conductaId,
    calificacionId,
    campo, // calificacion o comentarios
    valor
  ) => {
    const newCompetencias = modificarCalificacionHelper(
      competenciasKey,
      competencias,
      competenciaId,
      conductaId,
      calificacionId,
      campo,
      valor
    );
    //
    if (newCompetencias.error) {
      ErrorToast({ message: newCompetencias.error });
      return;
    }
    //
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        ...newCompetencias,
      })
    );
    setFueEditado(true);
  };
  const modificarCalificacionKPI = (
    competenciasKey,
    competencias,
    competenciaId,
    campo,
    valor,
    valorSecundario // para calificacion/cumplimiento
  ) => {
    const newCompetencias = modificarCalificacionKPIHelper(
      competenciasKey,
      competencias,
      competenciaId,
      campo,
      valor,
      valorSecundario
    );
    //
    if (newCompetencias.error) {
      ErrorToast({ message: newCompetencias.error });
      return;
    }
    //
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        ...newCompetencias,
      })
    );
    setFueEditado(true);
  };
  const modificarComentario = (field) => (e) => {
    const comentario = e.target.value;
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        [field]: comentario,
      })
    );
    setFueEditado(true);
  };
  const onSubmit = () => {
    const { puntaje_obtenido, puntaje_calibracion, comentarios_calibrador } =
      formulario;
    // @ts-ignore
    const { generales = null, areas = null, cargos = null, kpis = null, objetivos_cargo = null } =
      porcentajesFormulario;

    if (!comentarios_calibrador) {
      ErrorToast({ message: "El calibrador debe agregar un comentario." });
      return;
    }
    const calificaciones = obtenerCalificacionesFormulario(formulario, ETAPA);
    const data = {
      puntaje_obtenido: puntaje_obtenido,
      puntaje_calibracion: puntaje_calibracion,
      comentarios_calibrador: comentarios_calibrador,
      porcentaje_comp_general: generales,
      porcentaje_comp_area: areas,
      porcentaje_comp_cargo: cargos,
      porcentaje_kpis: kpis,
      porcentaje_objetivos_cargo: objetivos_cargo,
      ...calificaciones,
    };
    //
    dispatch(evaluacionActions.calibrar(data));
  };
  const onSubmitParcial = () => {
    const { puntaje_obtenido, puntaje_calibracion, comentarios_calibrador } =
      formulario;
    // @ts-ignore
    const { generales = null, areas = null, cargos = null, kpis = null, objetivos_cargo = null } =
      porcentajesFormulario;

    const calificaciones = obtenerCalificacionesFormulario(formulario, ETAPA);
    const data = {
      puntaje_obtenido: puntaje_obtenido,
      puntaje_calibracion: puntaje_calibracion,
      comentarios_calibrador: comentarios_calibrador,
      porcentaje_comp_general: generales,
      porcentaje_comp_area: areas,
      porcentaje_comp_cargo: cargos,
      porcentaje_kpis: kpis,
      porcentaje_objetivos_cargo: objetivos_cargo,
      es_parcial: true,
      ...calificaciones,
    };
    //
    dispatch(evaluacionActions.calibrar(data));
  };
  const sePuedeEditar = () => {
    const { estado: estadoEvaluacion } = evaluacion;
    if (estadoEvaluacion?.value === ESTADOS_EVALUACION.FINALIZADO) {
      return false;
    }
    const { calibrador } = formulario;
    if (!calibrador) {
      return false;
    }
    const { nombre, apellido, colaborador_id } = calibrador;
    // const nombreCalibrador = `${nombre} ${apellido}`;
    const usuarioColaboradorId = Number(usuario?.colaborador_id);
    // const esUsuarioCalibrador = usuario?.nombre_completo === nombreCalibrador;
    const esUsuarioCalibrador = usuarioColaboradorId === colaborador_id;
    const estado = formulario.estado.value;
    const estadoEditable =
      estado >= ESTADOS_FORMULARIO.EVALUADO &&
      estado <= ESTADOS_FORMULARIO.CALIBRADO;
    console.log("sePuedeEditar", estadoEditable, esUsuarioCalibrador);
    return estadoEditable && esUsuarioCalibrador;
  };
  const getInputComentarios = () => {
    let inputComentarios = {
      comentarios_evaluador: { show: true, editable: false },
      comentarios_calibrador: { show: true, editable: true },
      plan_accion: { show: false, editable: false },
      comentarios_evaluado: { show: false, editable: false },
    };
    return inputComentarios;
  };
  //
  useEffect(() => {
    dispatch(
      evaluacionActions.getFormulario({
        id: idEvaluacion,
        formularioId: idFormulario,
      })
    );
    //
    if (!empresa.id) {
      dispatch(colaboradorActions.getEmpresa({}));
    }
  }, []);
  //
  useEffect(() => {
    if (Object.keys(formulario).length > 0) {
      const dataPuntaje = calcularPuntajeFormulario(formulario, 2);
      setPorcentajesFormulario(dataPuntaje.porcentajes);
      let puntaje = dataPuntaje.puntajeObtenido;
      puntaje = Number(Number(puntaje).toFixed(2));
      const puntajeFormulario = Number(
        Number(formulario.puntaje_obtenido).toFixed(2)
      );
      //
      if (!habilitar_puntaje_evaluacion_mayor_100) {
        if (puntaje > 100.0) {
          puntaje = 100;
        }
      }
      //
      if (puntajeFormulario !== puntaje) {
        dispatch(
          evaluacionActions.setFormulario({
            ...formulario,
            puntaje_calibracion: puntaje,
            puntaje_obtenido: puntaje,
          })
        );
        //
      } else if (
        !formulario.puntaje_calibracion &&
        formulario.estado?.value === 2 &&
        !fueEditado
      ) {
        const competenciasDuplicadas =
          obtenerCompetenciasCalificadasCopia(formulario);
        dispatch(
          evaluacionActions.setFormulario({
            ...formulario,
            ...competenciasDuplicadas,
          })
        );
      }
    }
  }, [formulario]);
  //
  return (
    <div className="flex flex-col gap-4 mt-4 px-8">
      <div>
        <div className="border-b pb-3">
          <span className="font-bold text-sky-500 text-xl">Etapa:</span>{" "}
          <span className="text-xl">Calibración</span>
        </div>
      </div>
      {/*  */}
      {!formulario_loading && !evaluacion_loading && (
        <>
          {Object.keys(formulario).length > 0 && (
            <FormularioCalificaciones
              formulario={formulario}
              etapa={ETAPA}
              showPrevious={true}
              inputComentarios={getInputComentarios()}
              modificarCalificacionHandler={modificarCalificacion}
              modificarCalificacionKPIHandler={modificarCalificacionKPI}
              modificarComentario={modificarComentario}
              onSubmit={onSubmit}
              onSubmitParcial={onSubmitParcial}
              esEditable={sePuedeEditar()}
              submitButtonLabel={"Finalizar calibración"}
              puntuacionesCustom={getPuntuacionesCustom(evaluacion)}
              mostrarPesosIndicadores={
                evaluacion?.visualizar_pesos_indicadores_observacion
              }
              porcentajesFormulario={porcentajesFormulario}
            ></FormularioCalificaciones>
          )}
          {Object.keys(formulario).length < 1 && (
            <div className="flex justify-center border rounded-md p-2">
              No existe formulario
            </div>
          )}
        </>
      )}
      {(!!formulario_loading || !!evaluacion_loading) && (
        <div className="flex justify-center p-2">
          <LoadingSpinButton
            message={"Cargando formulario, por favor espere"}
          ></LoadingSpinButton>
        </div>
      )}
    </div>
  );
}
export default FormularioCalibracionPage;
