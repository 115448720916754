import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { Card } from "src/modules/common/components/Card";
import ColaboradorRow from "src/modules/common/components/ColaboradorRow";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { equipoActions } from "../handlers/redux";
import TabButton from "src/modules/common/components/TabButton";
import NineBox from "src/modules/evaluacion/components/Ninebox";
import { Spinner } from "react-bootstrap";
import BarChart from "src/modules/common/components/BarChart";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import SkeletonLoader from "src/modules/common/components/SkeletonLoader";

const options = ["General", "Resumen"]

function EquipoPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: colaboradorId } = useParams();
  const colaboradorNombre = location.state?.colaboradorNombre;

  const equipo = useSelector((state) => state.equipo?.equipo) || {};

  const subordinados = useSelector((state) => state.equipo?.subordinados) || [];
  const subordinados_loading = useSelector((state) => state.equipo?.subordinados_loading) || false;
  const subordinados_filter = useSelector((state) => state.equipo?.subordinados_filter) || [];
  const subordinados_count = useSelector((state) => state.equipo?.subordinados_count) || 0;

  const subordinados_colaborador = useSelector((state) => state.equipo?.subordinados_colaborador) || [];
  const subordinados_colaborador_loading = useSelector((state) => state.equipo?.subordinados_colaborador_loading) || false;
  const subordinados_colaborador_filter = useSelector((state) => state.equipo?.subordinados_colaborador_filter) || [];

  const equipo_ninebox_data = useSelector((state) => state.equipo?.equipo_ninebox_data) || [];
  const equipo_ninebox_data_loading = useSelector((state) => state.equipo?.equipo_ninebox_data_loading) || false;

  const equipo_informe_evaluaciones = useSelector((state) => state.equipo?.equipo_informe_evaluaciones) || {};
  const equipo_informe_evaluaciones_loading = useSelector((state) => state.equipo?.equipo_informe_evaluaciones_loading) || false;

  const { nombre = "" } = subordinados_filter;
  const { nombre: nombreColaborador = "" } = subordinados_colaborador_filter;
  const { promedios_evaluaciones = [], promedio_general = 0 } = equipo_informe_evaluaciones;

  const [tabSelected, setTabSelected] = useState(0);

  useEffect(() => {
    if (colaboradorId) {
      dispatch(equipoActions.subordinadosColaborador(colaboradorId));
    } else {
      dispatch(equipoActions.equipo({}));
      dispatch(equipoActions.subordinados({}));
      dispatch(equipoActions.getEquipoNineboxData({}));
      dispatch(equipoActions.getEquipoInformeEvaluaciones({}));
    }
    console.log("subordinadoooos", subordinados)
  }, []);

  const onChangeText = (event) => {
    dispatch(
      equipoActions.setSubordinadosFilter({ nombre: event.target.value })
    );
  };

  const onChangeTextColaborador = (event) => {
    dispatch(
      equipoActions.setSubordinadosColaboradorFilter({
        colaboradorId: colaboradorId,
        nombre: event.target.value,
      })
    );
  };

  const onSelectTab = (index) => {
    setTabSelected(index);
  };

  const toDetail = (id) => {
    navigate(`/equipo/${id}`);
  };

  const getHistorialBarChartData = () => {
    let data = [];
    if (promedios_evaluaciones.length) {
      let promediosEvaluacionesCopy = [...promedios_evaluaciones]
      // Ordenar por id en orden descendente
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.sort(
        (a, b) => a.id - b.id
      );
      // Tomar los primeros 6 elementos
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.slice(0, 6);
      data = promediosEvaluacionesCopy.map((p, idx) => ({
        x: idx + 1,
        y: p.puntaje_promedio,
        name: p.nombre,
      }));
    }
    return data;
  };

  return (
    <>
      <Header
        titulo={"Mi equipo"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
      ></Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        {!colaboradorId && (
          <div className="flex flex-col gap-2 ">
            <div className="text-lg font-bold">Tu jefe directo:</div>
            <div className="flex flex-row gap-2  items-center">
              {!equipo.jefe_directo?.foto_empleado && (
                <ProfileImage className="h-12 w-12 p-2"></ProfileImage>
              )}
              {!!equipo.jefe_directo?.foto_empleado && (
                <div className="w-12 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-12 w-12 rounded-md"
                    src={equipo.jefe_directo?.foto_empleado}
                    alt={`Foto Jefe`}
                  />
                </div>
              )}
              <div className="flex flex-col flex-grow text-zinc-700">
                <div className="text-sm font-bold">
                  {equipo.jefe_directo &&
                    `${equipo.jefe_directo?.apellido}, ${equipo.jefe_directo?.nombre}`}
                  {!equipo.jefe_directo && `Sin especificar`}
                </div>
                <div className="text-sm">
                  Cargo:{" "}
                  {equipo.jefe_directo?.cargo?.nombre || "Sin especificar"}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* MI EQUIPO */}
        {!colaboradorId && (
          <>
            {/* PESTAÑAS DE OPCIONES */}
            {subordinados_count > 0 && (
              <div className="flex flex-row flex-wrap gap-2 items-center">
                {options.map((option, idx) => (
                  <TabButton
                    title={option}
                    isActive={idx === tabSelected}
                    action={() => onSelectTab(idx)} />
                ))}
              </div>
            )}
            {/* PESTAÑA GENERAL */}
            {tabSelected === 0 && (
              <Card className=" gap-4 p-4">
                <div className="flex flex-col gap-2">
                  <div className="text-lg font-bold">Tu equipo</div>
                  <BuscarInput onChange={onChangeText} value={nombre}></BuscarInput>
                </div>
                <div className="flex flex-col gap-2">
                  {/* <div className="text-lg font-bold">Histórico</div> */}
                  <div>
                    <div className="border-b bg-zinc-400"></div>
                    {subordinados.length > 0 &&
                      subordinados.map((subordinado, idx) => (
                        <ColaboradorRow
                          key={`subordinado-${idx}`}
                          colaborador={subordinado}
                          onClick={() => {
                            toDetail(subordinado.id);
                          }}
                        ></ColaboradorRow>
                      ))}
                    {subordinados.length < 1 && (
                      <div className="w-full text-center p-4">
                        No tiene colaboradores asignados.
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            )}
            {/* PESTAÑA RESUMEN */}
            {tabSelected === 1 && (
              <div>
                <div className="flex flex-col gap-2 px-4">
                  <div className="text-lg font-bold">Resumen de potencial de tu equipo</div>
                  <Card className="gap-4">
                    {equipo_ninebox_data_loading && (
                      <div className="w-full text-center p-4">
                        <Spinner />
                      </div>
                    )}
                    {!equipo_ninebox_data_loading && equipo_ninebox_data.length > 0 && (
                      <NineBox nineboxData={equipo_ninebox_data} mostrarDescripcionesBoxes={true} extraClassName="pb-3" />
                    )}
                    {!equipo_ninebox_data_loading && equipo_ninebox_data.length < 1 && (
                      <div className="w-full text-center p-4">
                        No tiene una evaluación de potencial finalizada.
                      </div>
                    )}
                  </Card>
                  <div className="text-lg font-bold">Informe general de desempeño de mi equipo</div>
                  <div className="flex justify-between gap-4">
                    {!equipo_informe_evaluaciones_loading && (
                      <>
                        <Card className="w-8/12">
                          <>
                            <h2 className="font-bold">Historial de evaluaciones</h2>
                            <div className="-my-10">
                              <BarChart
                                data={getHistorialBarChartData()}
                                domainPadding={{ x: 35, y: [0, 0] }}
                                usingXLabels={false}
                                tickLabelsFontSize={14}
                              />
                            </div>
                          </>
                        </Card>
                        <Card className="w-4/12">
                          <>
                            <h2 className="font-bold">Promedio de desempeño</h2>
                            <div className="w-full h-full flex items-center justify-center">
                              <PercentageCircular
                                width={220}
                                height={220}
                                strokeWidth={8}
                                value={promedio_general}
                                content={
                                  <>
                                    <div className="font-bold text-2xl">
                                      {promedio_general || 0}%
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          </>
                        </Card>
                      </>
                    )}
                    {equipo_informe_evaluaciones_loading && (
                      <SkeletonLoader items={2} heights={["h-72", "h-72"]} widths={["w-8/12", "w-4/12"]} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {/* EQUIPO DE UN COLABORADOR */}
        {colaboradorId && (
          <Card className=" gap-4 p-4">
            <div className="flex flex-col gap-2">
              <div className="text-lg ">
                <span className="font-bold">Equipo de </span>
                <span className="">{colaboradorNombre}</span>
              </div>

              <BuscarInput
                onChange={onChangeTextColaborador}
                value={nombreColaborador}
              ></BuscarInput>
            </div>
            <div className="flex flex-col gap-2">
              {/* <div className="text-lg font-bold">Histórico</div> */}
              <div>
                <div className="border-b bg-zinc-400"></div>
                {subordinados_colaborador.length > 0 &&
                  subordinados_colaborador.map((subordinado, idx) => (
                    <ColaboradorRow
                      key={`subordinado-${idx}`}
                      colaborador={subordinado}
                      onClick={() => {
                        toDetail(subordinado.id);
                      }}
                    ></ColaboradorRow>
                  ))}
                {subordinados_colaborador.length < 1 && (
                  <div className="w-full text-center p-4">
                    No tiene colaboradores asignados.
                  </div>
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
    </>
  );
}

export default EquipoPage;
