import { useNavigate } from "react-router-dom";
import { formatDate } from "../helpers/helpers";
import SkeletonLoaderRow from "./SkeletonLoaderRow";
import React from "react";
import { TIPOS_EVALUACIONES } from "src/modules/evaluacion/helpers/constants";

function HistorialEvaluacionesTable({
  evaluaciones,
  colaborador = {},
  loading,
}) {
  const navigate = useNavigate();
  //
  const EvaluacionItem = ({ evaluacion }) => {
    const onClick = () => {
      navigate(`/evaluaciones/${evaluacion.id}/colaborador/${colaborador.id}`);
    };
    return (
      <div
        onClick={onClick}
        className="flex justify-between items-center border-b pb-3 hover:cursor-pointer"
      >
        <div>
          <div className="font-bold">{`${evaluacion.nombre}`}</div>
          <div>{`Fecha inicio: ${formatDate(evaluacion.fecha_inicio)}`}</div>
          <div>{`Fecha finalización: ${formatDate(
            evaluacion.fecha_finalizacion
          )}`}</div>
        </div>
        <div>
          <div className="bg-slate-200 rounded-md p-1 text-sm">
            {/* Para evaluación de desempeño se muestra el puntaje obtenido. */}
            {evaluacion.tipo_evaluacion === TIPOS_EVALUACIONES.DESEMPENO && (
              <>{`${evaluacion.puntaje_obtenido?.puntaje}% - ${evaluacion.puntaje_obtenido?.clasificacion}`}</>
            )}
            {/* Para evaluación de potencial se muestra el cuadrante al que pertenece el colaborador. */}
            {evaluacion.tipo_evaluacion === TIPOS_EVALUACIONES.POTENCIAL && (
              <>{evaluacion.box_obtenido}</>
            )}
          </div>
        </div>
      </div>
    );
  };
  //
  return (
    <div className="mt-4 px-4">
      {/* headers */}
      <div className="flex justify-between border-b pb-2 mb-4">
        <div className="font-bold">Evaluaciones creadas</div>
        <div className="font-bold">Resultado</div>
      </div>
      {/* body */}
      <div className="flex flex-col gap-4">
        {!loading && (
          <>
            {evaluaciones.length > 0 &&
              evaluaciones.map((evaluacion, idx) => (
                <EvaluacionItem
                  key={`evalu-${idx}`}
                  evaluacion={evaluacion}
                ></EvaluacionItem>
              ))}
            {evaluaciones.length < 1 && (
              <div className="p-2 border rounded-md mb-4">Sin resultados</div>
            )}
          </>
        )}
        {!!loading && (
          <div>
            {[1, 2, 3].map((l, i) => (
              <SkeletonLoaderRow key={`li-${i}`}></SkeletonLoaderRow>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default HistorialEvaluacionesTable;
