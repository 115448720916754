import React from "react";
import CollapseCard from "src/modules/common/components/CollapseCard";

export function FormularioCalificacionesPromedio({ formulario, etapa }) {
  const IndicadorPromedioFormularioItem = (props) => {
    const { descripcion, promedio_calificacion, comentarios } = props;
    return (
      <div className="flex flex-col gap-4 mb-4">
        <div className="flex justify-between">
          <div className="font-bold text-sm">{descripcion}</div>
          <div className="text-sm">{promedio_calificacion}%</div>
        </div>
        {/* Comentarios */}
        <div>
          <h3 className="font-bold text-slate-500 text-sm">Comentarios</h3>
          {comentarios.length > 0 ? (
            <div className="flex flex-col gap-2">
              {comentarios.map((com, idx) => (
                <div
                  key={`com-${idx}`}
                  className="border rounded-md p-2 whitespace-pre-wrap"
                >
                  {com}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-sm">No hay comentarios</div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {/* COMPETENCIAS GENERALES */}
      {formulario.generales && formulario.generales.length > 0 && (
        <div className="border-4x mb-6">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              <div>
                <span className="font-bold text-sky-500 text-xl">
                  Competencias:
                </span>{" "}
                <span className="text-xl">Competencias Generales</span>
              </div>
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {formulario.generales.map((cg, i) => (
                <CollapseCard
                  titulo={cg.nombre}
                  descripcion={cg.descripcion}
                  extraEndContent={
                    cg.peso ? (
                      <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                        <div>{cg.peso}</div>
                        <div>%</div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {cg.conductas.map((conducta, cidx) => (
                    <IndicadorPromedioFormularioItem {...conducta} />
                  ))}
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* COMPETENCIAS DE CARGO */}
      {formulario.cargos && formulario.cargos.length > 0 && (
        <div className="border-4x mb-6">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              <div>
                <span className="font-bold text-sky-500 text-xl">
                  Competencias:
                </span>{" "}
                <span className="text-xl">Competencias de Cargo</span>
              </div>
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {formulario.cargos.map((cg, i) => (
                <CollapseCard
                  titulo={cg.nombre}
                  descripcion={cg.descripcion}
                  extraEndContent={
                    cg.peso ? (
                      <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                        <div>{cg.peso}</div>
                        <div>%</div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {cg.conductas.map((conducta, cidx) => (
                    <IndicadorPromedioFormularioItem {...conducta} />
                  ))}
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* FIN COMPETENCIAS GENERALES */}
    </>
  );
}
