import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import FormularioCalificaciones from "../components/FormularioCalificaciones";
import { evaluacionActions } from "../handlers/redux";
import {
  calcularPuntajeFormulario,
  ESTADOS_EVALUACION,
  ESTADOS_FORMULARIO,
  ETAPAS,
  getPuntuacionesCustom,
  modificarCalificacionHelper,
  modificarCalificacionKPIHelper,
  obtenerCalificacionesFormulario,
} from "../helpers/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";
import { TIPOS_EVALUACIONES } from "../helpers/constants";

function FormularioEvaluacionPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idEvaluacion, idFormulario } = params;
  const ETAPA = ETAPAS.EVALUACION;
  const empresa = useSelector((state) => state.colaborador.empresa);
  const { habilitar_puntaje_evaluacion_mayor_100 = false } = empresa;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacion_loading = useSelector((state) => state.evaluacion.evaluacion_loading);
  const usuario = getUserFromCookie();
  const formulario = useSelector((state) => state.evaluacion.formulario);
  const formulario_loading = useSelector(
    (state) => state.evaluacion.formulario_loading
  );

  const [porcentajesFormulario, setPorcentajesFormulario] = useState({});

  const modificarCalificacion = (
    competenciasKey,
    competencias,
    competenciaId,
    conductaId,
    calificacionId,
    campo, // calificacion o comentarios
    valor
  ) => {
    const newCompetencias = modificarCalificacionHelper(
      competenciasKey,
      competencias,
      competenciaId,
      conductaId,
      calificacionId,
      campo,
      valor
    );
    //
    if (newCompetencias.error) {
      ErrorToast({ message: newCompetencias.error });
      return;
    }
    //
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        ...newCompetencias,
      })
    );
  };
  const modificarCalificacionKPI = (
    competenciasKey,
    competencias,
    competenciaId,
    campo,
    valor,
    valorSecundario // para calificacion/cumplimiento
  ) => {
    const newCompetencias = modificarCalificacionKPIHelper(
      competenciasKey,
      competencias,
      competenciaId,
      campo,
      valor,
      valorSecundario
    );
    //
    if (newCompetencias.error) {
      ErrorToast({ message: newCompetencias.error });
      return;
    }
    //
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        ...newCompetencias,
      })
    );
  };
  const modificarComentario = (field) => (e) => {
    const comentario = e.target.value;
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        [field]: comentario,
      })
    );
  };
  const onSubmit = () => {
    const { puntaje_obtenido, puntaje_evaluacion, comentarios_evaluador } =
      formulario;
    // @ts-ignore
    const { generales = null, areas = null, cargos = null, potenciales = null, kpis = null, objetivos_cargo = null } =
      porcentajesFormulario;
    if (!comentarios_evaluador) {
      ErrorToast({ message: "El evaluador debe agregar un comentario." });
      return;
    }
    const calificaciones = obtenerCalificacionesFormulario(formulario, ETAPA);
    const data = {
      puntaje_obtenido: puntaje_obtenido,
      puntaje_evaluacion: puntaje_evaluacion,
      comentarios_evaluador: comentarios_evaluador,
      porcentaje_comp_general: generales,
      porcentaje_comp_area: areas,
      porcentaje_comp_cargo: cargos,
      porcentaje_comp_potencial: potenciales,
      porcentaje_kpis: kpis,
      porcentaje_objetivos_cargo: objetivos_cargo,
      ...calificaciones,
    };
    //
    dispatch(evaluacionActions.evaluar(data));
  };
  const onSubmitParcial = () => {
    const { puntaje_obtenido, puntaje_evaluacion, comentarios_evaluador } =
      formulario;
    // @ts-ignore
    const { generales = null, areas = null, cargos = null, potenciales = null, kpis = null, objetivos_cargo = null } =
      porcentajesFormulario;

    const calificaciones = obtenerCalificacionesFormulario(formulario, ETAPA);
    const data = {
      puntaje_obtenido: puntaje_obtenido,
      puntaje_evaluacion: puntaje_evaluacion,
      comentarios_evaluador: comentarios_evaluador,
      porcentaje_comp_general: generales,
      porcentaje_comp_area: areas,
      porcentaje_comp_cargo: cargos,
      porcentaje_comp_potencial: potenciales,
      porcentaje_kpis: kpis,
      porcentaje_objetivos_cargo: objetivos_cargo,
      es_parcial: true,
      ...calificaciones,
    };
    //
    dispatch(evaluacionActions.evaluar(data));
  };
  const sePuedeEditar = () => {
    const { estado: estadoEvaluacion } = evaluacion;
    //
    if (estadoEvaluacion?.value === ESTADOS_EVALUACION.FINALIZADO) {
      return false;
    }
    const estado = formulario.estado?.value;
    const { nombre, apellido, colaborador_id } = formulario.evaluador;
    // const nombreEvaluador = `${nombre} ${apellido}`;
    const usuarioColaboradorId = Number(usuario?.colaborador_id);
    // const esUsuarioEvaluador = usuario?.nombre_completo === nombreEvaluador;
    const esUsuarioEvaluador = usuarioColaboradorId === colaborador_id;
    const estadoEditable =
      estado >= ESTADOS_FORMULARIO.ENVIADO &&
      estado < ESTADOS_FORMULARIO.CALIBRADO;
    //
    return esUsuarioEvaluador && estadoEditable;
  };
  const getInputComentarios = () => {
    let inputComentarios = {
      comentarios_evaluador: { show: true, editable: true },
      comentarios_calibrador: { show: false, editable: false },
      plan_accion: { show: false, editable: false },
      comentarios_evaluado: { show: false, editable: false },
    };
    //
    return inputComentarios;
  };
  //
  useEffect(() => {
    dispatch(
      evaluacionActions.getFormulario({
        id: idEvaluacion,
        formularioId: idFormulario,
      })
    );
    //
    if (!empresa.id) {
      dispatch(colaboradorActions.getEmpresa({}));
    }
  }, []);
  //
  useEffect(() => {
    if (Object.keys(formulario).length > 0) {
      const dataPuntaje = calcularPuntajeFormulario(formulario);
      setPorcentajesFormulario(dataPuntaje.porcentajes);
      let puntaje = dataPuntaje.puntajeObtenido;
      puntaje = Number(Number(puntaje).toFixed(2));
      const puntajeFormulario = Number(
        Number(formulario.puntaje_obtenido).toFixed(2)
      );
      //
      if (!habilitar_puntaje_evaluacion_mayor_100) {
        if (puntaje > 100.0) {
          puntaje = 100;
        }
      }
      if (formulario.puntaje_obtenido !== puntaje) {
        dispatch(
          evaluacionActions.setFormulario({
            ...formulario,
            puntaje_evaluacion: puntaje,
            puntaje_obtenido: puntaje,
          })
        );
      }
    }
  }, [formulario]);
  //
  return (
    <div className="flex flex-col gap-4 mt-4 px-8">
      <div>
        <div className="border-b pb-3">
          <span className="font-bold text-sky-500 text-xl">Etapa:</span>{" "}
          <span className="text-xl">Evaluación</span>
        </div>
      </div>
      {!formulario_loading && !evaluacion_loading && (
        <>
          {Object.keys(formulario).length > 0 && (
            <>
              <FormularioCalificaciones
                formulario={formulario}
                etapa={ETAPA}
                modificarCalificacionHandler={modificarCalificacion}
                modificarCalificacionKPIHandler={modificarCalificacionKPI}
                inputComentarios={getInputComentarios()}
                modificarComentario={modificarComentario}
                onSubmit={onSubmit}
                onSubmitParcial={onSubmitParcial}
                esEditable={sePuedeEditar()}
                puntuacionesCustom={getPuntuacionesCustom(evaluacion)}
                mostrarPesosIndicadores={
                  evaluacion?.visualizar_pesos_indicadores_observacion
                }
                habilitarOpcionNoAplica={
                  evaluacion.tipo_evaluacion !== TIPOS_EVALUACIONES.POTENCIAL
                } /* Para evaluaciones de potencial se debe deshabilitar la opción de No aplica en los formularios */
                mostrarNinebox={evaluacion.tipo_evaluacion === TIPOS_EVALUACIONES.POTENCIAL}
                porcentajesFormulario={porcentajesFormulario}
              ></FormularioCalificaciones>
            </>
          )}
          {Object.keys(formulario).length < 1 && (
            <div className="flex justify-center border rounded-md p-2">
              No existe formulario
            </div>
          )}
        </>
      )}
      {(!!formulario_loading || !!evaluacion_loading) && (
        <div className="flex justify-center p-2">
          <LoadingSpinButton
            message={"Cargando formulario, por favor espere"}
          ></LoadingSpinButton>
        </div>
      )}
    </div>
  );
}
export default FormularioEvaluacionPage;
