import React from "react";

/**
 * SkeletonLoader
 * 
 * A component that renders a series of gray blocks with a pulsing animation.
 * Useful for indicating that data is loading.
 * 
 * @param number [items=2] - The number of blocks to render.
 * @param string[] [heights=[]] - An array of heights to be applied to each block.
 * @param string[] [widths=[]] - An array of widths to be applied to each block.
 */
const SkeletonLoader = ({ items = 2, heights = [], widths = [] }) => {
    return (
        <div className="flex justify-between gap-4 mt-4 px-8 w-full animate-pulse">
            {Array.from({ length: items }).map((_, index) => (
                <div
                    key={index}
                    className={`bg-slate-200 rounded-md ${heights[index] || "h-52"} ${widths[index] || "w-full"}`}
                ></div>
            ))}
        </div>
    );
};

export default SkeletonLoader;
