import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const objetivoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    asignados: async () => {
      const asignados = await axios.get(
        `${API_URL}/objetivo-cargo/asignados/`,
        {
          headers,
        }
      );
      return asignados.data;
    },
    subordinados: async ({ subordinados_filter }) => {
      const {
        nombre = "",
        areaId = "",
        sucursalId = "",
        departamentoId = "",
      } = subordinados_filter;
      const subordinados = await axios.get(
        `${API_URL}/objetivo-cargo/subordinados/?search=${nombre}&area_id=${areaId}&sucursal_id=${sucursalId}&departamento_id=${departamentoId}`,
        {
          headers,
        }
      );
      return subordinados.data;
    },
    subordinado: async ({ id }) => {
      const subordinado = await axios.get(
        `${API_URL}/objetivo-cargo/subordinado/${id}/detail`,
        {
          headers,
        }
      );
      return subordinado.data;
    },
    colaboradorObjetivosUpdate: async ({ id, objetivos }) => {
      const response = await axios.post(
        `${API_URL}/objetivo-cargo/subordinado/${id}/update-objetivos`,
        { objetivos: objetivos },
        { headers }
      );
      return response;
    },
    objetivoCargoColaboradorHistorial: async ({ id }) => {
      const response = await axios.get(
        API_URL + `/objetivo-cargo/${id}/history`,
        {
          headers,
        }
      );
      return response.data;
    },
  };
};
